








































import {Component, Vue} from 'vue-property-decorator';
import {resolveError} from '@/utils/notifications';
import IStockMovementFilters from '@/types/IStockMovementFilters';
import {IDataTablePagination} from '@/types/IPagination';
import Filters from '@/components/Stock/Filters.vue';
import IStock from '@/types/IStock';
import IStockFilters from '@/types/IStockFilters';
import StockService from '@/services/StockService';
import StockTable from '@/components/Stock/StockTable.vue';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'Stock',
  components: {
    PageWrap,
    StockTable,
    Filters
  },
})
export default class Stock extends Vue {
  stock: IStock[] = [];
  filters: IStockFilters = {
    skuList: [],
    fulfillmentCenterIdList: [],
    dispositionList: [],
    dateFrom: null,
    dateTo: null,
    quantityFrom: null,
    quantityTo: null,
    page: 1,
    size: 10,
    totalElements: 0,
  };

  skuList: string[] = [];
  fulfillmentCenterIdList: string[] = [];
  dispositionList: string[] = [];

  loading: boolean = false;

  onFiltersChanged(filters: IStockMovementFilters) {
    this.filters = {...this.filters, ...filters};
  }

  paginationChanged(pagination: IDataTablePagination) {
    this.filters.page = pagination.page;
    this.filters.size = pagination.itemsPerPage;

    this.fetchData();
  }

  sortChanged(sortable: IStockMovementFilters) {
    this.filters.sortBy = sortable.sortBy;
    this.filters.sortDirection = sortable.sortDirection;

    this.fetchData();
  }

  created() {
    this.getSavedFilters()
    this.fetchFiltersData();
  }

  getSavedFilters() {
    const { filters } = getSavedPageData()
    if (filters) {
      this.filters = filters
    }
  }

  async fetchFiltersData() {
    try {
      const {
        dispositionList,
        skuList,
        fulfillmentCenterIdList
      } = await StockService.getFilters();
      this.dispositionList = dispositionList;
      this.skuList = skuList;
      this.fulfillmentCenterIdList = fulfillmentCenterIdList;
    } catch (e) {
      resolveError(e, 'fetch_filters');
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      const {
        stock,
        totalElements,
      } = await StockService.getAll(this.filters);
      savePageData({
        filters: this.filters
      })
      this.stock = stock;
      this.filters.totalElements = totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
