import axios from 'axios';
import IStockFilters from '@/types/IStockFilters';

export default class StockRepository {
  static getAll(filters: IStockFilters) {
    return axios.post(`/stock/all`, filters);
  }

  static download(filters: IStockFilters) {
    return axios.post(`/stock/export`, filters, {responseType: 'blob'});
  }

  static getFilters() {
    return axios.get(`/stock/forms`);
  }
}
