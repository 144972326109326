import IStock from '@/types/IStock';
import StockRepository from '@/repositories/StockRepository';
import IStockFilters from '@/types/IStockFilters';
import StockFactory from '@/factories/StockFactory';
import FileService from '@/services/FileService';

export default class StockService {
    static async getAll(filters: IStockFilters)
        : Promise<{ stock: IStock[], totalElements: number }> {
        const {data} = await StockRepository.getAll(filters);

        return {
            stock: data.content.map((item: any) => StockFactory.toStock(item)),
            totalElements: data.totalElements,
        };
    }

    static async downloadFile(filters: IStockFilters) {
        const {data} = await StockRepository.download(filters);

        const file = new FileService(data);
        file.downloadFile('Stock');
    }

    static async getFilters(): Promise<{ dispositionList: string[], skuList: string[], fulfillmentCenterIdList: string[] }> {
        const {data} = await StockRepository.getFilters();

        return {
            dispositionList: data.dispositionList,
            skuList: data.skuList,
            fulfillmentCenterIdList: data.fulfillmentCenterIdList
        };
    }
}
