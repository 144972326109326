import IStock from '@/types/IStock';

export default class StockMovementFactory {
  static toStock(data: any): IStock {
    return {
      date: data?.date?.replace('T', ' ').substring(0, 10),
      fulfillmentCenterId: data.fulfillmentCenterId,
      quantity: data.quantity,
      sku: data.sku,
      asin: data.asin,
      name: data.name,
      url: data.url,
      disposition: data.disposition
    };
  }
}
