

















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {IDataTablePagination} from '@/types/IPagination';
import IStock from '@/types/IStock';
import IStockFilters from '@/types/IStockFilters';

@Component({name: 'StockMovementTable',
  components: {}
})
export default class StockTable extends Vue {
  @Prop()
  data!: IStock[];

  @Prop()
  params!: IStockFilters;

  @Prop()
  loading!: boolean;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_DATE',
      value: 'date',
    },
    {
      text: 'SYSTEM_FULFILLMENT_CENTER_ID',
      value: 'fulfillmentCenterId',
    },
    {
      text: 'SYSTEM_SKU',
      value: 'sku',
    },
    {
      text: 'SYSTEM_DISPOSITION',
      value: 'disposition'
    },
    {
      text: 'SYSTEM_QUANTITY',
      value: 'quantity'
    },
  ];

  @Emit('fetchData')
  emitFetchData() {
    return;
  }

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  @Emit('sort')
  sort(value: { sortBy: string[], sortDesc: string[] }) {
    this.params.sortBy = value.sortBy[0];
    this.params.sortDirection = value.sortDesc[0] ? 'DESC' : 'ASC';
    return this.params;
  }

}
